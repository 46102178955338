<template>
  <div
    class="ju-container"
    v-lazy:background-image="{src: require('../../../assets/images/lunbo5.png')}"
  >
    <div class="page-outter">
      <div align="center">
        <img
          class="ju-title"
          v-lazy="require('../../../assets/images/title17.png')"
        />
        <span class="ju-desc">一起做有为青年</span>
        <div class="ju-d1">
          <div
            class="ju-d-item"
            v-for="(item,index) in tds"
            :key="index"
          >
            <img
              class="m1"
              v-lazy="item.icon"
            />
            <span class="s1">{{item.title}}</span>
            <span
              class="s2"
              v-html="item.desc"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "joinUs",
  data() {
    return {
      tds: [
        {
          icon: require("../../../assets/images/app-icon47.png"),
          title: "无限可能性的成长空间",
          desc: `
            作为行业领域快速成长的一员<br>
            无论是企业还是行业都有着广阔<br>
            发展潜力，同时公司提供完善<br>
            培训体系及公平透明的晋升路径
          `,
        },
        {
          icon: require("../../../assets/images/app-icon48.png"),
          title: "轻松和谐的团队氛围",
          desc: `
            开放的企业文化<br>
            扁平化的管理风格<br>
            还有一群专业有趣的伙伴<br>
            一起进步共同成长
          `,
        },
        {
          icon: require("../../../assets/images/app-icon49.png"),
          title: "诚意满满的公司福利",
          desc: `
            超出同岗位平均水平的薪资<br>
            入职缴纳五险一金<br>
            周末双休，提供午餐<br>
            年度团建旅游
          `,
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.ju-container {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 2.4rem;
}
.ju-title {
  width: 5.5rem;
  margin-top: 2.4rem;
}
.ju-desc {
  display: block;
  font-size: 0.36rem;
  color: #4a4a4a;
}
.ju-d1 {
  display: flex;
  justify-content: space-between;
  .ju-d-item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 236px;
    height: 300px;
    background: #fff;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
    margin-top: 2rem;
    .m1 {
      width: 50px;
    }
    .s1 {
      margin-top: 20px;
      font-size: 15px;
      color: #6aa5ff;
    }
    .s2 {
      font-size: 13px;
      color: #aaaaaa;
      margin-top: 20px;
      line-height: 1.8;
    }
  }
}
</style>